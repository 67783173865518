




























import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import useGlobalStorage from "@/composables/useGlobalStorage";

export default defineComponent({
    name: "Sidebar",
    setup() {
        const { globalStore } = useGlobalStorage()

        const links = [
            {
                name: 'Ladesäulen',
                route: 'ChargingStations',
                icon: 'battery-charging',
                subRoute: false
            },
            {
                name: 'Datenexport',
                route: 'DataExport',
                icon: 'clipboard-data',
                subRoute: false
            },
            {
                name: 'Verwaltung',
                route: 'Management',
                icon: 'gear',
                subRoute: false
            },
            {
                name: 'Ladesäulen Zuordnung',
                route: 'ManagementChargingStation',
                icon: 'file-bar-graph',
                subRoute: true
            },
            {
                name: 'RFID-Tags',
                route: 'ManagementRFIDTags',
                icon: 'tags',
                subRoute: true
            },
            {
                name: 'Mitarbeiter',
                route: 'ManagementEmployee',
                icon: 'person-fill',
                subRoute: true
            },
            {
                name: 'Kostenfaktor',
                route: 'CostFactor',
                icon: 'cash',
                subRoute: true
            },
            {
                name: 'Import Transaktionen',
                route: 'ImportExternalTransactions',
                icon: 'box-arrow-in-up',
                subRoute: false
            }
        ]

        const logout = () => {
            localStorage.removeItem('user')
            router.replace('/login')
        }

        return {
            links,
            globalStore,
            logout
        }
    }
})

