let authKey = ''

const TOKEN_KEY = 'user'

export function useAuth() {
    async function setToken(key: string) {
        await localStorage.setItem(TOKEN_KEY, key)

        authKey = key
    }

    async function getToken() {
        // console.log("getting Token", authKey)
        if (authKey !== '') {
            return authKey
        } else {
            const key = localStorage.getItem(TOKEN_KEY)
            // console.log(key)
            // const key = (await Storage.getItem({
            //     key: TOKEN_KEY
            // })).value

            if (key) {
                authKey = key
                return authKey
            }
            return 'error'
        }
    }

    return {
        setToken,
        getToken
    }
}