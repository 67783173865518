import Vue from 'vue'
import '@/plugins/bootstrap-vue'
import '@/plugins/composition-api'
import '@babel/polyfill'
import 'mutationobserver-shim'
import App from './App.vue'
import router from './router'
import store from './store'
import { useAPI } from "@/composables/useAPI";
import '@/themes/global.css'

Vue.config.productionTip = false

Vue.use({
  install(Vue) {
    const { api } = useAPI()

    Vue.prototype.$api = api;
  }
})

const vueInstance = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vueInstance
