import Vue from 'vue'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue, {
    BCalendar: {
        "labelPrevDecade": "Previous decade",
        "labelPrevYear": "Previous year",
        "labelPrevMonth": "Previous month",
        "labelCurrentMonth": "Current month",
        "labelNextMonth": "Next month",
        "labelNextYear": "Next year",
        "labelNextDecade": "Next decade",
        "labelToday": "Today",
        "labelSelected": "Selected date",
        labelNoDateSelected: "Kein Datum ausgewählt",
        "labelCalendar": "Calendar",
        "labelNav": "Calendar navigation",
        "labelHelp": "Use cursor keys to navigate calendar dates"
    },
    BModal: {
        cancelTitle: 'Abbrechen'
    }
})
Vue.use(BootstrapVueIcons)