import { reactive } from "@vue/composition-api";
import { useCompanyAPI } from "@/api-connectors/useCompanyAPI";
import router from "@/router";
import { Company } from "@/types/apiEntities";

let isInit = false;

const globalStore = reactive({
    currentCompany: {
        id: '1',
        name: ''
    }
})

const { requestCompanies } = useCompanyAPI()

const initGlobalStore = async () => {
    if (isInit) return;

    const response = await requestCompanies()
    if (response) {
        const currentCompany = (response?.data as Company[]).find(
            company => company.id === Number(router.currentRoute.params.companyId)
        )
        if (currentCompany) {
            globalStore.currentCompany.id = currentCompany.id?.toString() ?? ''
            globalStore.currentCompany.name = currentCompany.name
        } else globalStore.currentCompany = response.data[0]
    }

    isInit = true;
}

export default function useGlobalStorage() {

    initGlobalStore()
    return {
        globalStore,
        isInit
    }
}