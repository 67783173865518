import { useAPI } from "@/composables/useAPI";
import { AxiosResponse } from "axios";

export function useCompanyAPI() {
    const { api } = useAPI()

    const requestCompanies = async (): Promise<AxiosResponse | undefined> => {
        return await api.get('/companies')
            .catch(() => {
                return undefined;
            })
    }

    return {
        requestCompanies
    }
}