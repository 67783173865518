<template>
  <div id="app">
<!--    <div id="nav">-->
<!--    </div>-->
    <Sidebar/>

    <router-view  :class="($route.path !== '/' && $route.path !== '/login') ? 'router-styling' : ''"/>
  </div>
</template>

<script>
  import Sidebar from '@/components/Sidebar.vue'

  export default {
      name: 'App',
      components: {
          Sidebar
      },
      methods: {

      },

  }
</script>


<style lang="scss">
@import './assets/styles/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
</style>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  --primary: #263445;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

    .router-styling {
        margin-left: 260px;
        width: 80%;
        margin-top: 40px
    }
</style>
