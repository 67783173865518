import Axios, { AxiosInstance } from "axios";
import router from "@/router";
import { useAuth } from "@/composables/useAuth";

export const API_URL = ENV.API_URL

const { getToken } = useAuth()

export function useAPI() {

    const api: AxiosInstance = Axios.create({
        baseURL: API_URL,
    });

    function init() {
        console.log('api setup')

        api.interceptors.request.use(async function (config) {
            // Do something before request is sent
            config.headers["Authorization"] = 'Bearer ' + await getToken();
            return config;
        });

        api.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('user')
                router.replace({ name: 'Login' })
            }
            return Promise.reject(error);
        });
    }

    init();

    return {
        api
    }
}