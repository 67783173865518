import Vue from 'vue'
import VueRouter from 'vue-router'
import useGlobalStorage from "@/composables/useGlobalStorage";

Vue.use(VueRouter)

const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home
    // },
    {
        path: '/',
        meta: {
            auth: true
        },
        redirect: '/charging-stations'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/charging-stations',
        name: 'ChargingStations',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "charging-stations" */ '../views/ChargingStations.vue')
    },
    {
        path: '/data-export',
        name: 'DataExport',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "data-export" */ '../views/DataExport.vue')
    },
    {
        path: '/management/:companyId',
        name: 'Management',
        meta: {
            auth: true
        },
        redirect: '/management/:companyId/charging-station',
        component: () => import(/* webpackChunkName: "management" */ '../views/Management.vue'),
        children: [
            {
                path: '/management/:companyId/charging-station',
                name: "ManagementChargingStation",
                meta: {
                    auth: true
                },
                component: () => import(/* webpackChunkName: "charging-station" */ '../views/ManagementChargingStation.vue')
            },
            {
                path: '/management/:companyId/rfid-tags',
                name: "ManagementRFIDTags",
                meta: {
                    auth: true
                },
                component: () => import(/* webpackChunkName: "rfid-tags" */ '../views/ManagementRFIDTags.vue')
            }
        ]
    },
    {
        path: '/management-employee/:companyId',
        name: 'ManagementEmployee',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "management-employee" */ '../views/ManagementEmployee.vue'),
    },
    {
        path: '/cost-factor',
        name: 'CostFactor',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "cost-factor" */ '@/views/CostFactor.vue')
    },
    {
        path: '/import-external-transactions',
        name: 'ImportExternalTransactions',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "import-external-transactions" */ '@/views/ImportExternalTransactions.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta?.auth && !localStorage.getItem('user')) {
        next({ name: 'Login' })
    } else if (to.name === 'Login' && localStorage.getItem('user')) {
        next('/')
    } else {
        next()
    }
})

export default router

